<!-- eslint-disable -->
<script>
import Swal from "sweetalert2";
import VButton from "@/components/Button.vue";
/* import VIcon from '@/components/Icon.vue'; */
import VTextbox from "@/components/Textbox.vue";
import ForgotPass from "@/components/ForgotPass.vue";
import ChangePass from "@/components/ChangePass.vue";
import { removeUserData } from "../helpers/user";
import VueRecaptcha from "vue-recaptcha";

export default {
  components: {
    VButton,
    /* VIcon, */
    VTextbox,
    ForgotPass,
    ChangePass,
    VueRecaptcha
  },
  props: {
    page: String
  },
  data() {
    return {
      email: "",
      senha: "",
      passwordField: "",
      invalidPassword: 0,
      showForgotPass: false,
      showChangePass: false,
      site_key: "",
      recaptcha: null
    };
  },
  computed: {
    token() {
      return this.$route.params.token;
    },
  },
  methods: {
    mxVerify(res) {
      this.recaptcha = res;
    },
    signIn() {
      if (localStorage.getItem("user")) {
        localStorage.removeItem("user");
      }
      if (this.email !== "" && this.senha !== "" && this.recaptcha) {
        if (this.isEmailOrCPFValid()) {
          if (!this.$store.state.formLoading) {
            this.$store.commit("formLoading", true);
            this.$validator.validateAll().then(valid => {
              if (valid) {
                const regex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_.]){2,}(?=.{10,})/;
                if (!regex.test(this.passwordField)) {
                  this.$store.commit("formLoading", false);
                  this.$validator.errors.add({
                    msg: "CPF/CNPJ ou Senha inválida",
                    field: "email"
                  });
                  this.invalidPassword += 1;

                  if (this.invalidPassword > 2) {
                    Swal.fire({
                      title: "Sua senha está expirada",
                      text: `Por favor, redefina a sua senha.`,
                      type: "info",
                      confirmButtonText: "Ok"
                    }).then(result => {
                      if (result) {
                        this.showForgotPass = true;
                      }
                    });
                  }
                  return;
                }
                this.$store.commit("setUserPassword", this.passwordField);
                this.$http
                  .post(`${process.env.VUE_APP_HUB_BASE_URL2}/users/login`, {
                    email: this.email.replace(/\D/g, ""),
                    senha: this.senha
                  })
                  .then(({ data }) => {
                    if (data.emailToCode || data.phoneNumber) {
                      this.showEmailAndPhone(
                        data.emailToCode,
                        data.phoneNumber
                      );
                      // this.confirmEmailAndPhone(data.emailToCode, data.phoneNumber);
                    }
                    if (data.token) {
                      removeUserData();
                      if (localStorage.getItem("cardToUnlock"))
                        localStorage.removeItem("cardToUnlock");
                      this.$cookies.set("token", data.token, "30min");
                      const { redirect } = this.$route.query;
                      if (redirect) {
                        this.$router.push({ path: redirect });
                      } else {
                        this.$router.push({ name: "my-cards" });
                      }
                    } else if (
                      data.message &&
                      !data.emailToCode &&
                      !data.phoneNumber
                    ) {
                      Swal.fire({
                        title: "Sua senha está expirada",
                        text: `Sua senha expirou! Para sua segurança redefina sua senha seguindo o passo-a-passo enviado para o e-mail ${data.email} ou entre em contato com a nossa central de ajuda.`,
                        type: "info",
                        confirmButtonText: "Ok"
                      });
                    } else if (!data.ok) {
                      // this.$validator.errors.add({ msg: data.error, field: 'email' })
                      if (data.error === "Email ou senha inválidos!") {
                        this.$validator.errors.add({
                          msg: "CPF/CNPJ ou Senha inválida",
                          field: "email"
                        });
                      } else {
                        this.$validator.errors.add({
                          msg: data.error,
                          field: "email"
                        });
                      }
                    }
                  })
                  .catch(err => {
                    // this.$validator.errors.add({ msg: err, field: 'email' })
                    if (err === "Email ou senha inválidos!") {
                      this.$validator.errors.add({
                        msg: "CPF/CNPJ ou Senha inválida",
                        field: "email"
                      });
                    } else {
                      this.$validator.errors.add({ msg: err, field: "email" });
                    }
                  })
                  .then(() => this.$store.commit("formLoading", false));
              } else {
                this.$store.commit("formLoading", false);
              }
            });
          }
        } else {
          this.$validator.errors.add({
            msg: "Preencha com um E-mail, CPF ou CNPJ válido",
            field: "email"
          });
        }
      } else {
        this.$validator.errors.add({
          msg: `É necessário preencher ${
            this.email === "" ? "o email" : "a senha"
          }`,
          field: this.email === "" ? "email" : "password"
        });
      }
    },
    checkPassword(value) {
      this.passwordField = value;
    },
    isEmailOrCPFValid() {
      if (/@/.test(this.email)) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(this.email).toLowerCase());
      }
      const documentUnmasked = this.email.replace(/\W/g, "");
      if (documentUnmasked.length === 11 || this.validCNPJ(documentUnmasked)) {
        this.email = documentUnmasked;
        return true;
      }
      return false;
    },
    validCNPJ(value) {
      let cnpj = value;
      const b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

      cnpj = cnpj.replace(/[^\d]/g, "");

      if (cnpj.length !== 14) return false;
      if (/0{14}/.test(cnpj)) return false;

      // eslint-disable-next-line
      for (var i = 0, n = 0; i < 12; n += cnpj[i] * b[++i]);

      // eslint-disable-next-line
      if (cnpj[12] != ((n %= 11) < 2 ? 0 : 11 - n)) return false;

      // eslint-disable-next-line
      for (var i = 0, n = 0; i <= 12; n += cnpj[i] * b[i++]);

      // eslint-disable-next-line
      if (cnpj[13] != ((n %= 11) < 2 ? 0 : 11 - n)) return false;

      return true;
    },
    // checkTextSecuritySupport() {
    //   if (!CSS.supports('-webkit-text-security', 'disc')) return 'password'
    //   return 'text'
    // },
    async showEmailAndPhone(emailToCode, phoneNumber) {
      const inputOptions = new Promise(resolve => {
        setTimeout(() => {
          resolve({
            email: `E-mail: ${emailToCode}`,
            phone: `Celular: ${phoneNumber}`
          });
        }, 1000);
      });
      await Swal.fire({
        title: "Código de Acesso",
        input: "radio",
        html:
          '<p class="px-5">Selecione abaixo como deseja receber o seu código para acesso:</p>',
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
        closeOnConfirm: false,
        closeOnCancel: false,
        closeOnClickOutside: false,
        allowEscapeKey: false,
        inputOptions,
        customClass: {
          text: "px-5"
        },
        customStyle: {
          text: "font-size: 18px",
          label: "font-size: 18px",
          radio: "font-size: 18px"
        },
        inputValidator: value => {
          if (!value) {
            Swal.fire({
              title: "Seleção obrigatória",
              text:
                "É obrigatório a seleção de uma das opções para receber o código de login. Tente novamente, por favor.",
              type: "info",
              confirmButtonText: "Ok"
            });
          } else {
            this.$http
              .post(`${process.env.VUE_APP_HUB_BASE_URL2}/users/login`, {
                email: this.email.replace(/\D/g, ""),
                senha: this.senha,
                selectMethod: value
              })
              .then(({ data }) => {
                if (data.emailToCode || data.phoneNumber) {
                  this.confirmEmailAndPhone(
                    value,
                    value === "email" ? emailToCode : phoneNumber
                  );
                } else if (
                  data.message &&
                  !data.emailToCode &&
                  !data.phoneNumber
                ) {
                  Swal.fire({
                    title: "Sua senha está expirada",
                    text: `Sua senha expirou! Para sua segurança redefina sua senha seguindo o passo-a-passo enviado para o e-mail ${data.email} ou entre em contato com a nossa central de ajuda.`,
                    type: "info",
                    confirmButtonText: "Ok"
                  });
                } else if (!data.ok) {
                  Swal.fire({
                    title: "Código Inválido",
                    text:
                      "Seu código pode estar expirado ou inválido. <br> Clique no botão para tentar novamente",
                    type: "error",
                    confirmButtonText: "Ok"
                  });
                  this.$validator.errors.add({
                    msg: data.error,
                    field: "email"
                  });
                }
              })
              .catch(err =>
                this.$validator.errors.add({ msg: err, field: "email" })
              )
              .then(() => this.$store.commit("formLoading", false));
          }
        }
      });
    },
    confirmEmailAndPhone(selected, valueSelected) {
      Swal.fire({
        title: "Código enviado",
        text: `Digite o código enviado para o ${
          selected === "phone" ? "celular" : "e-mail"
        } ${valueSelected}`,
        input: "password",
        inputPlaceholder: "Código de 6 dígitos",
        inputAttributes: {
          maxlength: 6,
          autocapitalize: "off",
          autocorrect: "off"
        },
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
        closeOnConfirm: false,
        closeOnCancel: false,
        closeOnClickOutside: false,
        allowEscapeKey: false,
        preConfirm: async value => {
          this.$http
            .post(process.env.VUE_APP_HUB_BASE_URL2 + "/users/login", {
              email: this.email.replace(/\D/g, ""),
              senha: this.senha,
              code: value
            })
            .then(({ data }) => {
              if (data.token) {
                removeUserData();
                if (localStorage.getItem("cardToUnlock"))
                  localStorage.removeItem("cardToUnlock");
                this.$cookies.set("token", data.token, "30min");
                const { redirect } = this.$route.query;
                if (redirect) {
                  this.$router.push({ path: redirect });
                } else {
                  this.$router.push({ name: "my-cards" });
                }
              } else if (data.message && !data.emailToCode) {
                Swal.fire({
                  title: "Sua senha está expirada",
                  text: `Sua senha expirou! Para sua segurança redefina sua senha seguindo o passo-a-passo enviado para o e-mail ${data.email} ou entre em contato com a nossa central de ajuda.`,
                  type: "info",
                  confirmButtonText: "Ok"
                });
              } else if (!data.ok) {
                Swal.fire({
                  title: "Código Inválido",
                  html:
                    "Seu código pode estar expirado ou inválido. <br> Clique no botão para tentar novamente",
                  type: "error",
                  confirmButtonText: "Ok"
                });
                this.$validator.errors.add({ msg: data.error, field: "email" });
              }
            })
            .catch(err =>
              this.$validator.errors.add({ msg: err, field: "email" })
            )
            .then(() => this.$store.commit("formLoading", false));
        }
      });
    },
    actionToChangePassword() {
      Swal.fire({
        html: `
          <div style='text-align: justify;color: #6c757d!important; font-size: medium;'>
            <h3 style='font-size: 1.75rem;'>Olá!</h3>
            <br />
            Para sua segurança, pedimos que você redefina sua senha de acesso.
            Ao clicar em Continuar, você será redirecionado para a tela de redefinição da senha.
            Em caso de dificuldades para receber o código de autenticação (via e-mail ou SMS),
            por favor entre em contato conosco pelo Atendimento ao Cliente OnlyPay no WhatsApp 
            <a href='https://wa.me/5511981710193?text=Ol%C3%A1%2C+acabei+de+tentar+alterar+minha+senha%2C+pode+me+ajudar%3F' 
              target='_blank' style='color: #6c757d!important; text-decoration: none;'>
              (11) 9 8171-0193
            </a>
            ou E-mail 
            <a href='mailto:faleconosco@onlypay.com.br' style='color: #6c757d!important; text-decoration: none;'>
              faleconosco@onlypay.com.br
            </a>
            <br />
            <br />
            <center>Equipe OnlyPay</center>
          </div>
        `,
        type: "error",
        showConfirmButton: true,
        confirmButtonText: "Continuar",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
      // remove user data
      removeUserData();
    }
  },
  watch: {
    $route(to, from) {
      if (to.name === "changePassword") {
        this.showChangePass = true;
        this.actionToChangePassword();
      } else {
        this.showChangePass = false;
      }
    }
  },
  beforeMount() {
    if (window.location.origin !== "https://conta.onlypay.com.br") {
      this.site_key = process.env.VUE_APP_DEV_RECAPTCHA_SITE_KEY;
    } else {
      this.site_key = process.env.VUE_APP_PROD_RECAPTCHA_SITE_KEY;
    }

    if (this.$route.name === "changePassword") {
      this.showChangePass = true;
      this.actionToChangePassword();
    } else {
      this.showChangePass = false;
    }
    // TODO: message to disable temporary access app
    // Swal.fire({
    //   html: `
    //     <div style='text-align: left;color: #6c757d!important; font-size: medium;'>
    //       <h3 style='font-size: 1.75rem;'>Olá!</h3>
    //         Estamos realizando uma manutenção temporária em nosso site. Agradecemos a compreensão.
    //         <br />
    //         <br />
    //         <br />
    //         <center>Equipe OnlyPay</center>
    //     </div>
    //   `,
    //   type: 'warning',
    //   showConfirmButton: false,
    //   confirmButtonText: 'Voltar',
    //   allowOutsideClick: false,
    //   allowEscapeKey: false,
    //   allowEnterKey: false
    // })
  }
};
</script>

<template>
  <div class="sign-in" :class="{ 'no-padding': this.page === 'unlock' }">
    <div class="sign-in__header" v-if="this.page !== 'unlock'">
      <div class="sign-in__header--small">
        <img class="sign-in__header__logo" src="~@/assets/img/logo_white.svg" />
      </div>
      <div class="sign-in__header--large">
        <img class="sign-in__header__logo" src="~@/assets/img/logo_white.svg" />
      </div>
    </div>
    <div
      class="sign-in__form"
      :class="{ 'no-box-shadow': this.page === 'unlock' }"
    >
      <ForgotPass
        v-if="showForgotPass && !token"
        @close="(showForgotPass = false), (senha = '')"
      />
      <ChangePass v-else-if="showChangePass" />
      <form
        v-on:submit.prevent="signIn"
        v-if="!showChangePass && !showForgotPass && !token"
        class="sign-in__form__fieldset"
        :class="{ 'no-padding': this.page === 'unlock' }"
      >
        <p
          v-if="this.page !== 'unlock'"
          class="sign-in__form__fieldset__message"
        >
          <span>Olá!</span> digite seu CPF ou CNPJ e senha para acessar seus
          cartões.
        </p>
        <p
          v-if="this.page === 'unlock'"
          class="sign-in__form__fieldset__message px-0"
        >
          Olá! Você já tem cadastro na OnlyPay, para desbloquear seu novo cartão
          digite seu login e senha nos campos abaixo.
        </p>
        <v-textbox
          v-model="email"
          data-vv-as="Documento"
          label="CPF ou CNPJ"
          name="email"
          placeholder="Digite seu CPF ou CNPJ"
          :mask="['###.###.###-##', '##.###.###/####-##']"
          type="text"
          validate="required"
        />
        <v-textbox
          v-model="senha"
          id="password-field"
          @input="checkPassword"
          data-vv-as="Senha"
          label="Senha"
          name="password.password"
          placeholder="Digite a sua Senha"
          type="password"
        />
        <div class="mb-4">
          <vue-recaptcha :sitekey="site_key" @verify="mxVerify"></vue-recaptcha>
        </div>
        <div class="sign-in__form__fieldset__wrapper-submit">
          <div>
            <v-button
              class="sign-in__form__fieldset__button-submit"
              icon="arrow-right"
              bgColor="blue"
              :radius="true"
              :rebranding="true"
              :label="this.page === 'unlock' ? 'Desbloquear' : 'Entrar'"
            />
          </div>
          <a
            href="#"
            class="sign-in__form__fieldset__forget-password"
            @click.prevent="showForgotPass = true"
          >
            Esqueci a senha
          </a>
        </div>
      </form>
      <div class="sign-in__footer" v-if="this.page !== 'unlock'">
        &copy; 2023 <a href="https://www.onlypay.com.br">OnlyPay</a> - Soluções
        de pagamento
      </div>
    </div>
    <div class="sign-in__aside" v-if="this.page !== 'unlock'">
      <h2 class="sign-in__aside__text-1">
        Bem vindo à Only<strong>Pay</strong>
      </h2>
      <h3 class="sign-in__aside__text-2">Simples <span>assim!</span></h3>
    </div>
  </div>
</template>

<style lang="postcss">
.swal2-radio {
  display: block !important;
  label {
    display: block;
    text-align: left;
  }
  input {
    vertical-align: baseline;
  }
}
.textbox {
  display: flex;
  flex-direction: column;
  &:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }
  &__label {
    color: var(--color-blue-onlypay);
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0.3rem;
  }
  &__input {
    border: none;
    background-color: var(--color-white);
    padding: 1.1rem 2rem 1rem 1.5rem;
    &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s;
    }
    &[name="password"] {
      &[type="text"] {
        -webkit-text-security: disc;
      }
    }
  }
  &__view-password {
    top: 3rem !important;
    right: 2rem !important;
  }
}
</style>

<style lang="postcss" scoped>
.no-box-shadow {
  box-shadow: none !important;
}
.no-padding {
  padding: 0 !important;
}
.sign-in {
  height: 100%;
  padding-top: 100px;
  position: relative;
  @media (min-width: 992px) {
    display: flex;
  }
  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100px;
    background: var(--color-blue-onlypay);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99;

    &__logo {
      size: 8rem 4rem;
      display: flex;
    }
    &--small {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--color-white);
      width: 100%;
      height: 6rem;
      /* height: 12vh; */
      @media (min-width: 992px) {
        display: none;
      }
    }
    &--large {
      display: none;
      padding: 0 0 0 1.5rem;
      @media (min-width: 992px) {
        display: flex;
      }
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background: rgba(255, 255, 255, 0.88);
    position: relative;
    z-index: 10;
    @media (min-width: 992px) {
      background: #edeef0;
      box-shadow: 1px 0 1px 0 rgba(0, 0, 0, 0.2);
      width: 28rem;
    }
    &__fieldset {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      padding: 0 1.5rem;
      @media (max-width: 992px) and (orientation: landscape) {
        padding: 1.5rem;
      }

      &__message {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        color: var(--color-blue-onlypay);
        font-size: 1.3rem;
        font-weight: bold;
        line-height: 1.6rem;
        margin-bottom: 2rem;
        padding-right: 3rem;

        span {
          display: flex;
          font-size: 3.5rem;
          line-height: 5.5rem;
        }
        @media (max-width: 992px) and (orientation: landscape) {
          display: none;
        }
        @media (min-width: 992px) {
          padding-right: 7rem;
        }
      }
      &__wrapper-submit {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.5rem;
        @media (min-width: 992px) {
          flex-direction: row;
        }
      }
      &__button-submit {
        justify-content: center;
        align-items: center;
        display: flex;
        width: 200px !important;
      }
      &__forget-password {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color-blue-onlypay);
        margin-top: 2rem;
        @media (min-width: 992px) {
          width: 50%;
          margin-top: 0;
        }
      }
    }
  }
  &__footer {
    border-top: 0.0625rem solid var(--color-grey-light);
    font-size: 0.625rem;
    margin: 0 1.5rem;
    padding: 1rem 0;
  }
  &__aside {
    background-image: url("~@/assets/img/bg_account_login.png");
    background-position: center center;
    background-size: cover;
    color: var(--color-white);
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @media (min-width: 992px) {
      overflow: hidden;
      width: unset;
      height: unset;
      top: unset;
      left: unset;
      position: relative;
    }
    &__text-1 {
      display: none;
      font-size: 3rem;
      font-weight: 200;
      margin-left: -0.125rem;
      padding: 0.75rem 4rem;
    }
    &__text-2 {
      display: none;
      /* color: var(--color-green);
      font-size: 1.5rem;
      font-weight: 300;
      padding: .75rem 4rem; */
      font-family: "Trash Hand";
      font-size: 8rem;
      line-height: 6rem;
      opacity: 0.4;
      position: absolute auto 0.25em 0.3em auto;
      transform: rotate(-15deg);
      & span {
        display: block;
        margin-left: 5.5rem;
      }
    }
  }
}
</style>
